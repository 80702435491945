<template>
  <v-dialog
      v-model="dialog"
      width="800px"
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
  >
    <v-card>
      <v-toolbar flat>
        <v-card-title>
          <span>{{ title }} Interest Syllabus Instrument</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                  v-model="syllabusInstrument.title"
                  :error="$v.syllabusInstrument.title.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Title <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.syllabusInstrument.title.$error">Title is required</span>
              <span class="text-danger" v-if="errors.title" >* {{ errors.title[0] }}</span>
            </v-col>

            <v-col cols="12" md="12">
              <v-select
                  v-model="syllabusInstrument.interest_syllabus_id"
                  :error="$v.syllabusInstrument.interest_syllabus_id.$error"
                  :items="interestSyllabuses"
                  item-text="title"
                  item-value="id"
                  clearable
                  outlined
                  dense
              >
                <template v-slot:label>
                  Interest Syllabus <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.syllabusInstrument.interest_syllabus_id.$error">Interest Syllabus is required</span>
            </v-col>

            <v-col cols="12" md="3">
              Status
              <v-switch
                  v-model="syllabusInstrument.is_active"
                  :label="syllabusInstrument.is_active ? 'Active' : 'Inactive'"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            text
            x-large
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
           x-large
           dark
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import InterestSyllabusService from "@/services/cms/interest/syllabus/InterestSyllabusService";
import InterestSyllabusInstrumentService
  from "@/services/cms/interest/syllabus/instrument/InterestSyllabusInstrumentService";

const interestSyllabus = new InterestSyllabusService();
const syllabusInstrument = new InterestSyllabusInstrumentService();

export default {
  name: "CreateAndUpdate",
  data() {
    return {
      title: '',
      edit: false,
      dialog: false,
      loading: false,
      interestSyllabuses: [],
      errors: [],
      syllabusInstrument: {
        title: '',
        interest_syllabus_id: '',
        is_active: true,
      }
    }
  },
  validations: {
    syllabusInstrument: {
      title: { required },
      interest_syllabus_id: { required },
      is_active: { required }
    }
  },
  mounted() {
    this.getAllInterestSyllabus()
  },
  methods: {
    getAllInterestSyllabus(){
      interestSyllabus
          .all()
          .then(response => {
            this.interestSyllabuses = response.data.interestSyllabuses;
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },
    closeDialog(){
      this.dialog = false;
      this.resetForm();
    },
    createSyllabusInstrument(){
      this.title = 'Add';
      this.dialog = true;
      this.edit = false;
      this.resetForm();
    },
    editSyllabusInstrument(syllabusInstrument){
      this.title = "Edit"
      this.dialog = true;
      this.edit = true;
      this.syllabusInstrument = syllabusInstrument;
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        this.loading = true;
        if (!this.edit) this.create();
        else this.update();
      }
    },
    create: function() {
      syllabusInstrument
          .create(this.syllabusInstrument)
          .then(response =>{
            this.$snotify.success("Syllabus instrument added");
            this.loading = false;
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    update() {
      syllabusInstrument
          .update(this.syllabusInstrument.id,this.syllabusInstrument)
          .then(response => {
            this.loading = false;
            this.$snotify.success("Syllabus instrument updated");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    resetForm(){
      this.$v.$reset();
      this.syllabusInstrument = {
        title: '',
        interest_syllabus_id: '',
        is_active: true,
      }
    }
  }
}
</script>

<style scoped>

</style>
